import * as React from "react"
import { navigate } from 'gatsby-link'

import Seo from "../components/seo"
import Layout_page from "../components/layout_page";


function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

export default function Contact() {
    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    return (
        <div className="bg-def_grey_1 page_formatting">

            <Layout_page>

                <h1 className="">Kontaktformulär</h1>

                <div className="contact_form">
                    <form
                        name="contact"
                        method="post"
                        action="/thanks/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="contact" />
                        <div hidden>
                            <label>
                                Don't fill this out: <input name="bot-field" onChange={handleChange} />
                            </label>
                        </div>
                        <p className="text-neutral-400 mb-4">Lämna namn och email tomt om
                            du vill vara anonym.</p>
                        <div className="contact_form_section">
                            <label>
                                Namn
                                <br />
                                <input type="text" name="namn" onChange={handleChange} />
                            </label>
                        </div>
                        <div className="contact_form_section">
                            <label>
                                Email
                                <br />
                                <input type="email" name="email" onChange={handleChange} />
                            </label>
                        </div>
                        <div className="contact_form_section">
                            <label>
                                Meddelande
                                <br />
                                <textarea required name="meddelande" rows="8" onChange={handleChange} />
                            </label>
                        </div>
                        <div>
                            <button className="button_1" type="submit">Skicka</button>
                        </div>
                    </form>
                </div>

            </Layout_page>
        </div>
    )
}


/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Kontakta" />
